import React from "react"
import { Link, navigate } from "gatsby"

const PostLink = ({ post, lastPosts }) => (
  <div
    id="post-link"
    style={
      lastPosts
        ? {
            display: "flex",
            flex: "1",
            maxWidth: "100%",
            flexDirection: "row",
            // margin: "1%",
            // border: "1px solid #00000033",
            // borderRadius: "4px",
            // backgroundImage: `url(${
            //   require(`../images/${post.frontmatter.featuredImage}`).default
            // })`,
            // backgroundSize: "cover",
            // backgroundRepeat: "no-repeat",
            // backgroundPosition: "center",
          }
        : {
            display: "flex",
            flex: "1",
            maxWidth: "100%",
            flexDirection: "row",
            // flex: "1",
            // maxWidth: "100%",
            // flexDirection: "column",
            // margin: "1%",
            // border: "1px solid #00000033",
            // borderRadius: "4px",
            // backgroundImage: `url(${
            //   require(`../images/${post.frontmatter.featuredImage}`).default
            // })`,
            // backgroundSize: "30%",
            // backgroundRepeat: "no-repeat",
            // backgroundPosition: "left",
            // border: "1px solid #002A4733",
            borderRadius: "4px",
            // boxShadow: "0px 0px 1px #00003333",
            marginBottom: "1rem",
          }
    }
  >
    {!lastPosts ? (
      <div
        id="post-link-img"
        style={{
          flex: "1",
          // maxWidth: "100%",
          // flexDirection: "column",
          width: "30%",
          height: "calc(275px * 9 / 16)",
          // margin: "1%",
          // border: "1px solid #00000033",
          borderRadius: "4px 0px 0px 4px",
          backgroundImage: `url(${
            require(`../images/${post.frontmatter.featuredImage}`).default
          })`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "left",
          boxShadow: "0px 0px 15px #00003333 inset",
        }}
        onClick={e => {
          navigate(post.frontmatter.slug)
        }}
      >
        <Link
          to={post.frontmatter.slug}
          style={{ color: "#000000dd", textDecoration: "none" }}
        />
      </div>
    ) : null}
    <div
      // src={require(`../images/${post.frontmatter.featuredImage}`).default}
      style={
        lastPosts
          ? {
              // display: "flex",
              maxWidth: "100%",
              // height: "calc(394px * 9 / 16)",
              paddingBottom: "1rem",
              // background: `linear-gradient(
              //   180deg,
              //   #00000000 0%,
              //   #00000033 0%
              // )`,
              // alignItems: "flex-start",
              // justifyContent: "flex-start",
              // textAlign: "right",
            }
          : {
              display: "flex",
              flex: "2",
              // maxWidth: "100%",
              // paddingRight: "1rem",
              paddingLeft: "1rem",
              // background: `linear-gradient(
              //   180deg,
              //   #00000000 0%,
              //   #00000033 0%
              // )`,
              alignItems: "center",
              justifyContent: "flex-start",
              textAlign: "left",
            }
      }
    >
      <Link
        to={post.frontmatter.slug}
        style={{
          color: "#000", //"#000034dd",
          textDecoration: "none",
        }}
      >
        <h4
          style={{
            margin: "0px",
            // textShadow: "1px 1px 0px #000000aa"
          }}
        >
          {post.frontmatter.title}
        </h4>
        <p
          style={{
            fontSize: "0.85rem",
            // textShadow: "1px 1px 0px #00000077",
            margin: "0px",
          }}
        >
          {post.frontmatter.author} - {post.frontmatter.date}
        </p>
        {!lastPosts ? (
          <p
            style={{
              fontSize: "0.85rem",
              // textShadow: "1px 1px 0px #00000077",
              margin: "0px",
            }}
          >
            {post.excerpt}
          </p>
        ) : null}
      </Link>
    </div>
  </div>
)

export default PostLink
