import * as React from "react"
import { graphql, StaticQuery } from "gatsby"

import PostLink from "./post-link"

export default function Posts({ column, lastPosts }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___date] }
          ) {
            edges {
              node {
                id
                excerpt(pruneLength: 250)
                frontmatter {
                  date(formatString: "DD/MM/YYYY", locale: "fr")
                  slug
                  title
                  author
                  featuredImage
                }
              }
            }
          }
        }
      `}
      render={data => {
        const { allMarkdownRemark } = data // data.markdownRemark holds your post data
        const { edges } = allMarkdownRemark
        const Posts = lastPosts
          ? edges
              .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
              .slice(0, 3)
              .map(edge => (
                <PostLink
                  key={edge.node.id}
                  post={edge.node}
                  column={column}
                  lastPosts={lastPosts}
                />
              ))
          : edges
              .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
              .map(edge => (
                <PostLink key={edge.node.id} post={edge.node} column={column} />
              ))
        return (
          <div
            id={lastPosts ? "last-posts" : "all-posts"}
            // style={{
            //   backgroundImage: `url(${require("../images/" + img).default})`,
            //   backgroundSize: "cover",
            //   backgroundRepeat: "no-repeat",
            //   backgroundPosition: "center",
            //   // marginBottom: `1.45rem`,
            //   height: "480px",
            //   maxHeight: "1000px",
            // }}
            className={`post-list ${lastPosts ? "last" : "all"}`}
            // style={{ alignSelf: "flex-start" }}
          >
            {/* <Img fluid={img} /> */}
            <div
              style={
                lastPosts
                  ? {
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                      margin: `0 auto`,
                      maxWidth: 960,
                      padding: `1rem 1.0875rem`,
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      // background: "#00003407",
                      borderTop: "2px solid #000",
                      borderRight: "1px solid #0003",
                      borderBottom: "1px solid #0003",
                      borderLeft: "1px solid #0003",
                      alignSelf: "flex-start",
                      // borderRadius: "4px",
                      marginLeft: ".9rem",
                      // backgroundColor: "#00000044",
                      // color: "#eee",
                    }
                  : {
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                      width: "100%",
                      margin: `0 auto`,
                      maxWidth: 960,
                      paddingTop: "2em",
                      paddingBottom: "2em",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                    }
              }
            >
              <h3>{lastPosts ? "Derniers articles" : "Les articles"}</h3>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                {Posts}
              </div>
            </div>
          </div>
        )
      }}
    />
  )
}
