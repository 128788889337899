/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Social from "./social"
import Newsletter from "./newsletter"
import "./layout.css"
import Posts from "./posts"

const Layout = ({ children, blog }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const [white, setWhite] = React.useState("")

  const [scrollPosition, setScrollPosition] = React.useState(0)

  const [alpha, setAlpha] = React.useState(0)

  const handleScroll = () => {
    // console.log(window.pageYOffset)

    const position = window.pageYOffset

    if (position != 0) {
      if (position < 400) {
        setWhite("white")
        setAlpha((position * 0.98) / 400)
      } else {
        setWhite("white")
        setAlpha(0.98)
      }
    } else {
      setWhite("")
      setAlpha(0)
    }
  }

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <>
      {/* <Header siteTitle={data.site.siteMetadata?.title || `Title`} type={blog ? "blog" : null} /> */}
      <Header
        siteTitle={data.site.siteMetadata?.title || `Title`}
        type={blog ? "blog" : null}
        isSticky
        white={white}
        style={{
          background: `rgb(255, 255, 255, ${alpha})`,
          backdropFilter: `blur(${alpha * 2}rem`,
        }}
      />
      {/* <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0 1.0875rem 1.45rem`,
        }}
      > */}
      <main>{children}</main>
      <Newsletter></Newsletter>
      <Posts />
      <Social />
      <footer
        style={{
          background: "#000",
          // marginTop: `2rem`,
          margin: `0 auto`,
        }}
      >
        <div
          style={{
            background: "#000",
            // marginTop: `2rem`,
            margin: `0 auto`,
            maxWidth: 960,
            padding: `1.45rem 1.0875rem`,
            color: "white",
            textAlign: "center",
          }}
        >
          © {data.site.siteMetadata?.title || `Title`}{" "}
          {new Date().getFullYear()}, Built with
          {` `}
          <a href="https://www.gatsbyjs.com" style={{ color: "white" }}>
            Gatsby
          </a>
        </div>
      </footer>
      {/* </div> */}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
