import * as React from "react"
import { useState } from "react"

const Newsletter = () => {
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [surname, setSurname] = useState("")

  return (
    <section
      id="newsletter"
      style={{
        paddingTop: "2em",
        paddingBottom: "2em",

        // height: "calc(100vw * 9 /16)",
        // maxHeight: "1000px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          // height: "100%",
          margin: `0 auto`,
          width: "100%",
          maxWidth: 960,
          padding: `1.45rem 1.0875rem`,
          // justifyContent: "center",
          // alignItems: "center",
        }}
      >
        {/* <div style={{ display: "flex", marginBottom: `1.45rem` }}>
          <div style={{ marginRight: `1.45rem` }}>
            <h2>Les dons de l'Esprit</h2>

            <p>Je rédige actuellement un eBook sur les dons de l'Esprit.</p>

            <p>
              Pour recevoir le lien de téléchargement lorsqu'il sera prêt,
              inscrivez-vous au moyen du formulaire ci-dessous.
            </p>
            <p>
              Vous pouvez aussi me soutenir en faisant un{" "}
              <Link to="#dons">don</Link>.
            </p>
          </div>
          <BookCover thickness="30">
            <img src={livre} alt="Couverture Les dons de l'Esprit"/>
          </BookCover>
        </div> */}

        {/* <link
        href="//cdn-images.mailchimp.com/embedcode/classic-10_7.css"
        rel="stylesheet"
        type="text/css"
      /> */}
        {/* <style type="text/css">
	#mc_embed_signup{background:#fff; clear:left; font:14px Helvetica,Arial,sans-serif; }

</style> */}
        <h2>Newsletter</h2>

        <form
          action="https://gmail.us5.list-manage.com/subscribe/post?u=a5f4b770c9f33ae844a80d787&amp;id=437d46ac6e"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          className="validate"
          target="_blank"
          novalidate
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <input
              type="text"
              value={name}
              placeholder="Prénom *"
              name="FNAME"
              className="required"
              id="mce-FNAME"
              onChange={e => setName(e.target.value)}
              style={{ flex: "1" }}
            />

            <input
              type="text"
              value={surname}
              placeholder="Nom de famille *"
              name="LNAME"
              className="required"
              id="mce-LNAME"
              onChange={e => setSurname(e.target.value)}
              style={{ flex: "1" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
            }}
          >
            <input
              type="email"
              value={email}
              placeholder="Email *"
              name="EMAIL"
              className="required email"
              id="mce-EMAIL"
              onChange={e => setEmail(e.target.value)}
              style={{ flex: "1" }}
            />
            <input
              type="submit"
              value="Inscription"
              name="subscribe"
              id="mc-embedded-subscribe"
              className="bouton bleu"
            />
          </div>
          <div id="mce-responses" className="clear">
            <div
              className="response"
              id="mce-error-response"
              style={{ display: "none" }}
            ></div>
            <div
              className="response"
              id="mce-success-response"
              style={{ display: "none" }}
            ></div>
          </div>
          <div
            style={{ position: "absolute", left: "-5000px" }}
            aria-hidden="true"
          >
            <input
              type="text"
              name="b_a5f4b770c9f33ae844a80d787_437d46ac6e"
              tabindex="-1"
              value=""
            />
          </div>
        </form>
      </div>
    </section>
  )
}

export default Newsletter
