import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import {
  FaHandHoldingHeart,
  FaFeatherAlt,
  FaMailBulk,
  FaPhotoVideo,
  FaInfo,
} from "react-icons/fa"

import { FiInfo } from "react-icons/fi"

const Header = ({ type, siteTitle, isSticky, white, style }) => {
  const { background } = style || {
    background: "transparent",
    backdropFilter: "none",
  }
  return (
    <header
      style={
        isSticky
          ? {
              background: background, // "#00406efa", //`rebeccapurple`,
              // marginBottom: `1.45rem`,
              position: "fixed",
              top: "0px",
              left: "0px",
              width: "100vw",
              zIndex: 3000,
            }
          : {
              background: "transparent", //`rebeccapurple`,
              // marginBottom: `1.45rem`,
            }
      }
      className={white}
    >
      <div
        style={{
          position: "relative",
          // display: "flex",
          // justifyContent: "center",
          // alignItems: "flex-start",
          // margin: `0 auto`,
          width: "100vw",
          // boxShadow: "0px 1px 3px #00000055",
          // zIndex: 100,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            margin: `0 auto`,
            maxWidth: 960,
            padding: `1.45rem 1.0875rem`,
            borderBottom: "1px solid #ffffffaa",
          }}
        >
          <h1
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "0px",
              paddingBottom: "0px",
              fontSize: "1.432rem",
              color: "white",
            }}
          >
            {/* {type === "blog" ? (
          <Link
            to="/"
            style={{
              display: "flex",
              alignItems: "center",
              color: `white`,
              textDecoration: `none`,
            }}
          >
            <HiOutlineChevronLeft />
          </Link>
        ) : (
          ""
        )} */}
            <Link
              to="/"
              style={{
                color: `white`,
                textDecoration: `none`,
              }}
            >
              {siteTitle}
            </Link>
            {/* {type === "blog" ? (
          <>
            &nbsp;/&nbsp;
            <Link
              to="/blog"
              style={{
                color: `white`,
                textDecoration: `none`,
              }}
            >
              {" "}
              Blog
            </Link>
          </>
        ) : (
          <></>
        )} */}
          </h1>

          {/* {type === "blog" ? (
        <nav>
          <Link to="#all-posts">Tous les articles</Link>
          <Link to="#last-posts">Derniers articles</Link>
          <Link to="/blog/dons" className="bouton clair">
            Me soutenir
          </Link>
        </nav>
      ) : ( */}
          <nav>
            {/* <Link to="/a-propos">
              Présentation
            </Link> */}
            <Link to="/medias">
              {/* <FaPhotoVideo /> */}
              Médias
            </Link>
            {/* <Link to="/itineraire">Itinéraire</Link> */}
            <Link to="/contact">
              {/* <FaMailBulk /> */}
              Contact
            </Link>
            <Link to="/blog">
              {/* <FaFeatherAlt /> */}
              Blog
            </Link>
            <Link to="/dons" className="bouton clair">
              <FaHandHoldingHeart size={26} />
            </Link>
          </nav>
          {/* )} */}
        </div>
      </div>
      {/* {type === "blog" ? (
        <div
          style={
            isSticky
              ? {
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  margin: `0 auto`,
                  width: "100vw",
                  // background: "#a68a80", //`rebeccapurple`,
                  // boxShadow: "0px 1px 3px #00000055",
                  zIndex: 10,
                }
              : {
                  background: "transparent", //`rebeccapurple`,
                }
          }
        >
          <nav
            className="blog-nav"
            style={{
              borderBottom: "1px solid #ffffffaa",
              padding: `.7rem .35rem`,
            }}
          >
            <Link to="#all-posts">Tous les articles</Link>
            <Link to="#last-posts">Derniers articles</Link>
            <Link to="#newsletter">Je m'abonne</Link>
          </nav>
        </div>
      ) : null} */}
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
