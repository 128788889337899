import * as React from "react"
import { Link } from "gatsby"

import { FaTelegramPlane } from "react-icons/fa"
import { FaInstagram } from "react-icons/fa"

const Social = () => (
  <section
    style={{
      paddingTop: "1em",
      paddingBottom: "1em",
      // backgroundColor: "#a68a80",
      // height: "calc(100vw * 9 /16)",
      // maxHeight: "1000px",
    }}
  >
    <div
      style={{
        // display: "flex",
        // height: "100%",
        margin: `0 auto`,
        maxWidth: 960,
        // padding: `1.45rem 1.0875rem`,
        // justifyContent: "center",
        // alignItems: "center",
        textAlign: "center",
        // color: "#4F3A35",
      }}
    >
      <p>
        Retrouvons-nous sur les réseaux sociaux / {/* </h2> */}
        {/* <div style={{ display: "flex", marginBottom: `1.45rem` }}> */}
        {/* <div
        style={{
          marginRight: `1.45rem`,
          color: "#73544d",
          textAlign: "center",
          fontSize: "1.35em",
        }}
      > */}
        {/* <p> */}
        <Link to="https://bit.ly/3oG3qtU" style={{ color: "#000" }}>
          <FaTelegramPlane
            style={{ fontSize: "1.5em", marginBottom: "-10px" }}
          ></FaTelegramPlane>
          {/* t.me/larencontre */}
        </Link>{" "}
        <Link
          to="https://bit.ly/3gCUD7R"
          style={{ color: "#000" }}
        >
          <FaInstagram
            style={{ fontSize: "1.5em", marginBottom: "-10px" }}
          ></FaInstagram>
          {/* @sebastienfgil */}
        </Link>
      </p>
      {/* </div> */}
    </div>
    {/* </div> */}
  </section>
)

export default Social
